// @flow
import React from 'react';
import styled from 'styled-components';
import Arrow from '../icons/Arrow';
import Button from './button';

export default ({
  className,
  left = false,
  ...rest
}: {
  className: string,
  left: boolean,
  rest: any,
}) => (
  <Button variant="fab" color="secondary" className={className} {...rest}>
    <LargeArrow left={left} />
  </Button>
);

const LargeArrow = styled(Arrow)`
  && {
    width: 45%;
    ${({ left }) => left && 'transform: rotate(180deg);'};
  }
`;
