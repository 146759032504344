// @flow
import React from 'react';
import styled from 'styled-components';
import CarouselButton from './CarouselButton';
import Frame from './rvp/Frame';
import Track from './rvp/Track';
import View from './rvp/View';
import ViewPager from './rvp/ViewPager';
import type { Node } from 'react';

// const animations = [
//   {
//     prop: 'scale',
//     stops: [[-200, 0.85], [0, 1], [200, 0.85]],
//   },
//   {
//     prop: 'opacity',
//     stops: [[-200, 0.15], [0, 1], [200, 0.15]],
//   },
// ];

const animations = [
  {
    prop: 'scale',
    stops: [[-200, 0.85], [0, 1], [200, 0.85]],
  },
  {
    prop: 'opacity',
    stops: [[-200, 0.15], [0, 1], [200, 0.15]],
  },
];

type Props = {
  children: (number => Node) | Node,
  viewsToShow?: number,
  height?: number,
};
type State = {
  view: number,
};

const HEIGHT = 640;

export default class Carousel extends React.Component<Props, State> {
  state = { view: 0 };

  track: any;

  onViewChange = (i: number) => this.setState({ view: i });

  render() {
    const { children, viewsToShow, height } = this.props;

    let chdrn = children;
    if (typeof children == 'function') chdrn = children(this.state.view);

    const numberOfViews = React.Children.count(chdrn);

    return (
      <ViewPager style={{ minHeight: height ? height : HEIGHT }}>
        <Frame style={{ marginLeft: -20, marginRight: -20, outline: 'none' }}>
          <Track
            ref={c => (this.track = c)}
            viewsToShow={viewsToShow ? viewsToShow : 'auto'}
            align={0.5}
            onViewChange={this.onViewChange}
            style={{ display: 'flex' }}
            // infinite
            // animations={animations}
          >
            {React.Children.map(chdrn, child => (
              <View>{child}</View>
            ))}
          </Track>
          <CarouselButtonStyled
            disabled={this.state.view == 0}
            left
            onClick={() => this.track.prev()}
          />
          <CarouselButtonStyled
            disabled={this.state.view == numberOfViews - 1}
            onClick={() => this.track.next()}
          />
        </Frame>
      </ViewPager>
    );
  }
}

const CarouselButtonStyled = styled(CarouselButton)`
  && {
    position: absolute;
    top: 49%;
    ${props => (props.left ? 'left: 8px' : 'right: 8px;')};
  }
`;
